
  .bounce {
      -moz-animation: bounce 3s infinite;
      -webkit-animation: bounce 3s infinite;
      animation: bounce 3s infinite;
      + .meridian-label {
        display: inherit !important;
      }
  }

  //overrides default map css to hide labels
  .meridian-label {
  //  display: none;
  }
  
  //overrides default map css to move controls to left side.
  .meridian-zoom-controls {
    left: 15px;
    bottom: 15px;
    right: inherit !important;
    //bottom: inherit !important;
  }

  .imc-map-container{
    position: relative; 
    min-height: 500px;
    //flex-basis: 70%;
    flex-grow: 9;
    order:1;
    /*.imc-meridian-map{
      float: left;
    }*/
  }
  
  //this style overrides a global img style which was breaking the map scale. (reset.css)
  .meridian-map-background div img{
    max-width: unset !important;
    vertical-align: unset !important;
    width: auto!important;
  }

  //.imc-map-dockRight 
  //in _floor-plan.scss

  @-moz-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      -moz-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -moz-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    60% {
      -moz-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }

  @-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    60% {
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    40% {
      -moz-transform: translateY(-30px);
      -ms-transform: translateY(-30px);
      -webkit-transform: translateY(-30px);
      transform: translateY(-30px);
    }
    60% {
      -moz-transform: translateY(-15px);
      -ms-transform: translateY(-15px);
      -webkit-transform: translateY(-15px);
      transform: translateY(-15px);
    }
  }
  